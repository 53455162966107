<template>
	<div class="pd16_15 bg-w">
		<div class="ft16 ftw600 cl-main">企业微信群机器人通知设置</div>
		<div class="mt16">
			<a-alert message="温馨提示" type="info">
				<p slot="description">
					需要创建一个企业内部群，群需要把员工和门店相关的人员都拉进去，并开通企业微信群机器人，复制群机器人的Webhook地址填入;
					订单类通知人员请填写企业内部群里面的员工手机号，如果手机号不正确就不能被提醒，另外预约类的则通知手机端具有员工预约操作权限的人员，不在通知订单类通知人员
				</p>
			</a-alert>
		</div>
		<a-spin :spinning="loading">
			<div class="flex center" style="width: 800px;min-height: 400px; margin-top: 80px;">
				<div>
					<div class="flex alcenter">
						<span class="ft14 cl-main">webhook地址:</span>
						<div class="ml10">
							<a-input v-model="url" type="text" style="width: 300px;"></a-input>
						</div>
					</div>
					<div class="mt16 flex alcenter">
						<span class="ft14 cl-main">订单类通知人员:</span>
						<div class="ml10" style="width: 400px;">
							<div v-for="(item,index) in mobile" :key="index" :class="{mt10:index>0}">
								<a-input v-model="mobile[index]" placeholder="请输入" style="width: 60%; margin-right: 8px" />
								<a-icon @click="delAct(index)" type="minus-circle-o" />
							</div>
							<div :class="{mt10:mobile.length > 0}">
								<a-button type="dashed" style="width: 60%" @click="addAct">
									<a-icon type="plus" /> 新增一条
								</a-button>
							</div>
						</div>
					</div>

					<div class="mt16 flex center">
						<a-button @click="saveAct" type="primary">确定保存</a-button>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				url: '',
				mobile: [],
				loading: false,
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			addAct() {
				this.mobile.push('');
			},
			delAct(index) {
				this.mobile.splice(index, 1);
			},
			loaddata() {
				this.loading = true;
				this.$http.api('admin/getWebHookUrl').then(res => {
					this.loading = false;
					this.url = res.url;
					this.mobile = res.mobile;
				}).catch(res => {
					this.loading = false;
				});
			},
			saveAct() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/saveWebHookUrl', {
					url: this.url,
					mobile: this.mobile
				}).then(res => {
					this.loading = false;
					this.$message.success('操作成功');
				}).catch(res => {
					this.loading = false;
				});
			}
		}
	}
</script>

<style>

</style>
